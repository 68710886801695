import React, { useEffect } from 'react';
import { Card, CardContent, CardMedia, Typography, IconButton, Stack, CardActions } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { MobileStepper, useTheme } from "@mui/material";
import SliderProggressBar from '../customComponents/SliderProggressBar';
import useMediaQuery from '@mui/material/useMediaQuery';
import CardHeading from '../customComponents/CardHeading';
import HealthContent from './HealthContent';
import PhysiotherapyContent from './PhysiotherapyContent';
import DieteticsContent from './DieteticsContent';
import PsychosocialSupportContent from './PsychosocialSupportContent';


// Slide settings for react-slick
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const cards = [
  {
    title: 'BAKIM HİZMETLERİ',
    subTitle: 'Emanetinize Özenle Bakıyoruz',
    description: 'Yaşlılara sunulan bakım hizmetleri, yaşlıların günlük yaşamlarını daha rahat ve desteklenmiş bir şekilde sürdürmelerine yardımcı olmayı amaçlar. Yaşlılara kişisel hijyen ve bakım hizmetleri sunulur. Bu, yeme, içme, giyinme, banyo, tuvalet ihtiyaçlarını karşılamayı içerir. Bakıcılar, yaşlılara yemek yeme, ilaç alma ve günlük kişisel bakım rutinlerinde yardımcı olabilirler. Yaşlılar için sağlıklı ve dengeli beslenmeyi teşvik etmek amacıyla özel beslenme programları ve yemek hizmetleri sunulur. Beslenme uzmanları tarafından hazırlanan menüler, yaşlıların beslenme ihtiyaçlarına uygun olarak düzenlenir.',
    image: 'carerServices.jpeg',
  },
  {
    title: 'SAĞLIK HİZMETLERİ',
    subTitle: 'Herşeyin Başı Sağlık',
    content: <HealthContent />,
    image: 'healthServices.jpeg',
  },
  {
    title: 'FİZYOTERAPİ HİZMETLERİ',
    subTitle: 'Sosyalleşmek ve Eğlenmek İçin Her Fırsatı Değerlendiriyoruz',
    content: <PhysiotherapyContent />,
    description: ``,
    image: 'physioTherapyServices.jpeg',

  },
  {
    title: 'BESLENME VE DİYET HİZMETLERİ',
    subTitle: 'Yaşlılar için Hareketle Gelen Huzur: Fizyoterapiyle Hayata Yeniden Adım Atın!',
    content: <DieteticsContent />,
    image: 'nutritionAndDietServices.jpeg',
  },
  {
    title: 'GÜVENLİK HİZMETLERİ',
    subTitle: '7/24 Kesintisiz Güvenlik',
    description: 'Kurumumuzda kameralar ve izinli giriş çıkılarla 7/24 kesintisiz güvenlik hizmeti verilmektedir.',
    image: 'securityServices.jpeg',
  },
  {
    title: 'PSİKOSOSYAL DESTEK HİZMETLERİ',
    subTitle: 'Yaşlılar İçin Yalnız Değiliz: Psikososyal Destekle Huzur Dolu Günler',
    content: <PsychosocialSupportContent />,
    image: 'psychosocialServices.jpeg',

  },
];


const CarouselOfServices = () => {

  const cardsLength = cards.length;
  const theme = useTheme();
  const isDarkTheme = theme.palette.mode === 'dark';
  const [index, setActiveStep] = React.useState(0);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [cardMediaWidth, setCardMediaWidth] = React.useState('50%');
  const [cardContentWidth, setCardContentWidth] = React.useState('50%');

  const [bgColor, setBgColor] = React.useState();


  useEffect(() => {
    if (isDarkTheme) {
      setBgColor('rgba(0, 0, 0, 0.3)');

    } else {
      setBgColor('rgb(245, 245, 245, 0.3)');
    }
  }, [isDarkTheme]);


  useEffect(() => {
    if (isSmallScreen) {
      setCardMediaWidth('100%');
      setCardContentWidth('100%');
    } else {
      setCardMediaWidth('50%');
      setCardContentWidth('50%');
    }
  }, [isSmallScreen]);


  const goToNextPicture = () => {
    let nextPage = (index + 1) % cardsLength;
    setActiveStep(nextPage);
  };

  const goToPrevPicture = () => {
    let prevPage = (index - 1 + cardsLength) % cardsLength;
    setActiveStep(prevPage);
  };
  const currentCard = cards[index];
  return (

    <Card key={index} elevation={4} sx={{ mt: 2, pt: 2, px: 1, borderRadius: 4, backgroundColor: bgColor }}>

      <CardHeading title={currentCard.title} subTitle={currentCard.subTitle} bgColor={bgColor}

      />

      <Stack spacing={2} direction={isSmallScreen ? "column" : "row"} marginTop={1}  >

        <CardMedia sx={{ width: cardMediaWidth, borderRadius: 4 }}
          component="img"
          alt={currentCard.title}
          //width="200"
          height="400"
          image={currentCard.image}
        />

        <CardContent sx={{ width: cardContentWidth, borderRadius: 4, backgroundColor: bgColor }} >
          <Typography variant="body2" >
            {currentCard.content ? currentCard.content : currentCard.description}
          </Typography>
        </CardContent>
      </Stack>
      <CardActions sx={{ borderRadius: 4, m: 1, backgroundColor: bgColor }}>
        <SliderProggressBar handleAction={goToNextPicture} index={index} interval={1000} />
      </CardActions>

      <CardActions>

        <MobileStepper
          sx={{ width: '100%', backgroundColor: bgColor }}
          variant="dots"
          position="static"
          steps={cardsLength}
          activeStep={index}
          backButton={
            <IconButton
              onClick={goToPrevPicture}
            >
              <KeyboardArrowLeftIcon fontSize='medium' />

            </IconButton>
          }
          nextButton={
            <IconButton
              onClick={goToNextPicture}
            >
              <KeyboardArrowRightIcon fontSize='medium' />
            </IconButton>
          }
        />
      </CardActions>

    </Card>
  );
};
export default CarouselOfServices;
