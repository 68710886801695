import React, { useEffect } from 'react';
import CarouselOfServices from './CarouselOfServices';
import { Alert, Box, Card, CardContent, CardHeader, Container, Paper, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles'
import PageHeading from '../customComponents/PageHeading';
import CardHeading from '../customComponents/CardHeading';
import CottageIcon from '@mui/icons-material/Cottage';

const Services = () => {

  const theme = useTheme();
  const isDarkTheme = theme.palette.mode === 'dark';
  const [bgColor, setBgColor] = React.useState();


  useEffect(() => {
    if (isDarkTheme) {
      setBgColor('rgba(0, 0, 0, 0.3)');

    } else {
      setBgColor('rgb(245, 245, 245, 0.3)');
    }
  }, [isDarkTheme]);



  return (
    <Paper sx={{
      borderRadius: 8,
      p: 2,
      backgroundColor: 'transparent',
    }}
      elevation={0}>
      <PageHeading title="Hizmetlerimiz" />
      <Card sx={{
        borderRadius: 4,
        p: 2,
        backgroundColor: 'transparent',
      }}
        elevation={2}>

        <CardHeading title="Hoş geldiniz!" subTitle='Dört Mevsim Yaşam Köşkü' bgColor={bgColor}
          iconComponent={<CottageIcon color="primary" />}
        /> 
        <Stack spacing={4} direction="column" alignItems="center" sx={{ mb: 2, mt:2 }}>
          <CardContent sx={{ mb: 2, borderRadius: 4, backgroundColor: bgColor, }}>

            <Typography variant="body1" gutterBottom component="div" sx={{}}>
              <b>Dört Mevsim Yaşam Köşkü</b> olarak, sizlere sevgi, saygı ve güvenle dolu bir yaşam sunmak için buradayız.
              <br></br>
              Sunduğumuz geniş hizmet yelpazesiyle, yaşlı bireylerin konforunu ve mutluluğunu ön planda tutuyoruz.
              <br></br>
              İhtiyaçlarınıza özel olarak tasarlanmış hizmetlerimizle, sıcak bir ortamda huzurlu bir yaşam sürmenizi sağlıyoruz.
            </Typography>
          </CardContent>

          <Stack sx={{ m: 4, pt:2, }}>
            <CarouselOfServices />
          </Stack>

          <CardContent sx={{ mb: 2, borderRadius: 4, backgroundColor: bgColor, }}>

            <Typography variant="body1" gutterBottom component="div">

              Biz, <b>Dört Mevsim Yaşam Köşkü</b> olarak sakinlerimize aile ortamında bir yaşam sunmayı taahhüt ediyoruz.
              <br></br>
              Sizleri aramızda görmek ve birlikte güzel anılar biriktirmek için sabırsızlanıyoruz.
            </Typography>
          </CardContent>

        </Stack>


      </Card>
    </Paper>
  );
};

export default Services;
