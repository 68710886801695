import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { Link, Stack } from '@mui/material';
import { blue } from '@mui/material/colors';

export default function AddressInfo() {
    const phoneNumberOwner = '+90 541 389 35 00';
    const phoneNumber = '+90 545 235 81 86';
    const mail = 'dmyk2011@hotmail.com';
    return (
        <List sx={{ width: '100%', }}>
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <HomeIcon fontSize='large' color='success' />
                </ListItemAvatar>
                <ListItemText
                    primary={<Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="caption"
                        color="text.secondary"
                    >
                        Adres:
                    </Typography>}
                    secondary={
                        <Stack direction="column">
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                Mutlukent Mah. Binsesin Sitesi 
                            </Typography>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                1964. Sk. No 39 
                            </Typography>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                Çankaya/Ankara
                            </Typography>
                        </Stack>
                    }
                />
            </ListItem>
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <PhoneIcon fontSize='large' color='success' />
                </ListItemAvatar>
                <ListItemText
                    primary={<Typography
                        sx={{ display: 'flex' }}
                        component="span"
                        variant="caption"
                        color="text.secondary"
                    >
                        Telefon:
                    </Typography>}
                    secondary={
                        <Stack direction="column">
                            <Typography
                                sx={{ display: 'flex' }}
                                // variant="h6" 
                                // component="title"

                                component="strong"
                                variant="subtitle2"
                            >
                                {/* <a href={`tel:+9${phoneNumber}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    {phoneNumber}
                                </a> */}
                                <Link href={`tel: ${phoneNumberOwner}`} underline="none" color="inherit" sx={{ color: blue[500] }}

                                    css={{ backgroundColor: 'transparent', onmouseover: { backgroundColor: 'transparent' } }}>
                                    {phoneNumberOwner}
                                </Link>
                            </Typography>
                            <Typography
                                sx={{ display: 'flex' }}
                                // variant="h6" 
                                // component="title"

                                component="strong"
                                variant="subtitle2"
                            >
                                {/* <a href={`tel:+9${phoneNumber}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    {phoneNumber}
                                </a> */}
                                <Link href={`tel: ${phoneNumber}`} underline="none" color="inherit" sx={{ color: blue[500] }}

                                    css={{ backgroundColor: 'transparent', onmouseover: { backgroundColor: 'transparent' } }}>
                                    {phoneNumber}
                                </Link>
                            </Typography>
                        </Stack>
                    }
                />
            </ListItem>
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <EmailIcon fontSize='large' color='success' />
                </ListItemAvatar>
                <ListItemText
                    primary={<Typography
                        sx={{ display: 'flex' }}
                        component="span"
                        variant="caption"
                        color="text.secondary"
                    >
                        E-Posta:
                    </Typography>}
                    secondary={
                        <Stack direction="column">
                            <Typography
                                sx={{ display: 'flex' }}
                                // variant="h6" 
                                // component="title"

                                component="strong"
                                variant="subtitle2"
                            >
                                {/* <a href={`tel:+9${phoneNumber}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    {phoneNumber}
                                </a> */}
                                <Link href={`mailto: ${mail}`} underline="none" color="inherit" sx={{ color: blue[500] }}

                                    css={{ backgroundColor: 'transparent', onmouseover: { backgroundColor: 'transparent' } }}>
                                    {mail}
                                </Link>
                            </Typography>
                        </Stack>
                    }
                />
            </ListItem>
        </List>
    );
}
