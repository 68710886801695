import React, { } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function PageHeading({ title, iconComponent }) {
    return (
        <Box sx={{
            borderRadius: 4,
            px: 4,
            py: 2,
            mb: 2,
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
        }}
            display="flex" alignItems="center" alignContent='center'>
            {iconComponent && <Box marginRight={1}>{iconComponent}</Box>}
            <Typography variant="h4" component="h1" gutterBottom sx={{
                color: 'primary.main',
                fontFamily: 'Madimi One',
                fontWeight: 'bold'
            }}>
                {title.toLocaleUpperCase('tr-TR')}
            </Typography>
        </Box>
    );
}
