import React from 'react';

const PhysiotherapyContent = () => {
    // Your component logic goes here

    return (
        <div>
 
            <span >Huzurevlerinde yaşlılara sunulan fizyoterapi hizmetleri, yaşlıların fiziksel sağlığını iyileştirmeyi, hareketliliklerini artırmayı ve yaşam kalitelerini yükseltmeyi amaçlar. Bu hizmetler, yaşlıların günlük yaşam aktivitelerini daha bağımsız bir şekilde gerçekleştirebilmelerini sağlamak için tasarlanmıştır. İşte huzurevlerinde yaşlılara sunulan fizyoterapi hizmetlerinin bazı örnekleri
            </span>
            <ol>
                <li>
                    <b>Fizyoterapi Değerlendirmesi : </b>  Fizyoterapistler, yaşlıların fiziksel durumlarını değerlendirirler. Bu değerlendirme, yaşlıların hareketlilik seviyelerini, kas gücünü, esnekliğini ve dengesini belirlemeyi içerir. Buna ek olarak, yaşlıların günlük yaşam aktivitelerini ve hareket kısıtlamalarını anlamak için yaşam alanlarında değerlendirme yapılabilir.

                </li>
                <li>
                    <b>Bireysel Egzersiz Programları : </b>
                    Yaşlılara, bireysel ihtiyaçlarına ve hedeflerine uygun olarak özel egzersiz programları hazırlanır. Bu egzersiz programları, kas gücünü artırmayı, esnekliği geliştirmeyi, dengeyi sağlamayı ve ağrıyı azaltmayı hedefler.
                </li>
                <li>
                    <b>Hareket Tedavisi ve Mobilizasyon : </b>
                    Yaşlılara, hareket yeteneklerini artırmak için çeşitli hareket tedavisi teknikleri ve mobilizasyon egzersizleri uygulanır. Bu teknikler, eklemlerin hareketliliğini artırmak, kas gerginliğini azaltmak ve sertlikleri hafifletmek için kullanılır.
                </li>
                <li>
                    <b>Yürüme ve Denge Egzersizleri : </b>
                    Yaşlılara, yürüme ve denge egzersizleri yaparak günlük yaşam aktivitelerini daha güvenli bir şekilde gerçekleştirmeleri için destek sağlanır. Bu egzersizler, yaşlıların düşme riskini azaltmak ve güvenli bir şekilde dolaşabilmelerini sağlamak amacıyla tasarlanmıştır.
                </li>
                <li>
                    <b> Ağrı Yönetimi ve Masaj Terapisi : </b>
                    Fizyoterapistler, yaşlıların ağrılarını yönetmelerine yardımcı olmak için çeşitli teknikler kullanabilirler. Bu teknikler arasında masaj terapisi, sıcak-soğuk tedaviler, germe egzersizleri ve postür düzeltme egzersizleri bulunabilir.
                </li>
                <li>
                    <b> Teçhizat ve Yardımcı Cihaz Seçimi : </b>
                    Fizyoterapistler, yaşlılara uygun teçhizat ve yardımcı cihazları seçmelerinde yardımcı olabilirler. Bu cihazlar arasında yürüme destekleri, bastonlar, tekerlekli sandalyeler ve egzersiz ekipmanları yer alabilir.
                </li>
                <li>
                    <b> Teçhizat ve Yardımcı Cihaz Seçimi : </b>
                    Fizyoterapistler, yaşlılara uygun teçhizat ve yardımcı cihazları seçmelerinde yardımcı olabilirler. Bu cihazlar arasında yürüme destekleri, bastonlar, tekerlekli sandalyeler ve egzersiz ekipmanları yer alabilir.
                </li>
            </ol>
            <span>
                Bu hizmetler, yaşlıların yaşam kalitesini artırmak, hareketliliklerini iyileştirmek ve bağımsızlıklarını sürdürmelerine yardımcı olmak için tasarlanmıştır. Fizyoterapistler, yaşlıların bireysel ihtiyaçlarını dikkate alır ve kişiselleştirilmiş tedavi planları oluşturarak en uygun tedaviyi sağlamaya çalışırlar. Ayrıca, yaşlıların fizyoterapi sürecindeki ilerlemelerini düzenli olarak izler ve tedavi planlarını gerektiğinde güncellerler.
            </span> 
        </div >
    );
};

export default PhysiotherapyContent;