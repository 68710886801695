import React, { useRef, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Container, Paper, Stack } from '@mui/material'; 
import HomeAccordion from '../Home/HomeAccordion';
import EnrtyCard from './EntryCard';
import GalleryCarousel from './GalleryCarousel';
import PageHeading from '../customComponents/PageHeading';

const Activities = () => {

  const theme = useTheme();
  const isDarkTheme = theme.palette.mode === 'dark';
  const [bgColor, setBgColor] = React.useState();


  useEffect(() => {
    if (isDarkTheme) {
      setBgColor('rgba(0, 0, 0, 0.3)');

    } else {
      setBgColor('rgb(245, 245, 245, 0.3)');
    }
  }, [isDarkTheme]);


  return (

    <Paper sx={{
      borderRadius: 8,
      p: 2,
      backgroundColor: 'transparent',
    }}
      elevation={0}>
        
      <PageHeading title="Etkinliklerimiz" />
      <Stack direction="column" spacing={4} justifyContent="center" >
        <EnrtyCard />
        <GalleryCarousel /> 
      </Stack>
    </Paper>
  );
};

export default Activities;
