import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export default function SliderProggressBar({ handleAction, index, interval }) {
  const [progress, setProgress] = React.useState(0);
 
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((currentProgress) => {
        if (currentProgress === 100) {
            handleAction();
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(currentProgress + diff, 100);
      });
    }, interval);

    return () => {
      clearInterval(timer);
    };
  }, [index, interval]);

  return (
    <Box sx={{ width: '100%', borderRadius: 4 }}>
      <LinearProgress variant="determinate" sx={{borderRadius: 4}} value={progress} />
    </Box>
  );
}