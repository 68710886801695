import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './components/Home/Home';
import Services from './components/Services/Services';
import Contact from './components/Communication/Contact';
import AboutUs from './components/AboutUs/AboutUs';
import './styles/styles.css';
import Layout from './components/Layout';
import Activities from './components/Activities/Activities';
import { customTheme } from './themes/customTheme';
import { ThemeProvider } from '@mui/material/styles'
import useDarkModePreference from './hooks/useDarkModePreference';
import ScrollToTop from './components/ScrollToTop';

const ColorModeContext = React.createContext({ toggleColorMode: () => { } });

const App = () => {
   
  const isDarkMode = useDarkModePreference();
  const [mode, setMode] = React.useState(isDarkMode? 'dark': 'light');

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );
  
  const theme = React.useMemo(
    () =>
      customTheme(mode),
    [mode],
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}> 
        <BrowserRouter>
        <ScrollToTop /> {/* Ensure ScrollToTop component is rendered */}
          <Routes>
            <Route path="/" element={<Layout mode={mode} toggleColorMode={colorMode.toggleColorMode} />}>
              <Route index element={<Home />} />
              <Route path="/hizmetlerimiz" element={<Services />} />
              <Route path="/etkinliklerimiz" element={<Activities />} />
              <Route path="/iletisim" element={<Contact />} />
              <Route path="/hakkimizda" element={<AboutUs />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};
 
export default App;
