import React, { useRef, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Alert, CardHeader, Paper } from '@mui/material';
import PageHeading from '../customComponents/PageHeading';
import CardHeading, { CardHeadingSubTitle } from '../customComponents/CardHeading';

export default function EnrtyCard() {
    const theme = useTheme();
    const isDarkTheme = theme.palette.mode === 'dark';
    const [bgColor, setBgColor] = React.useState();

    useEffect(() => {
        if (isDarkTheme) {
            setBgColor('rgba(0, 0, 0, 0.3)');

        } else {
            setBgColor('rgb(245, 245, 245, 0.3)');
        }
    }, [isDarkTheme]);



    return (
        <Paper sx={{
            borderRadius: 4,
            backgroundColor: 'transparent',
            p: 2,

        }} elevation={2}>
            <CardHeading title="Dört Mevsim Yaşam Köşkü" bgColor={bgColor}
                iconComponent={<img src="dmykLogo.jpeg" alt="Dört Mevsim Yaşam Köşkü" style={{ width: 40, height: 40 }} />}
            />

            <Card sx={{ borderRadius: 4, display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', sm: 'column', md: 'row', backgroundColor: bgColor }, p: 4, my: 2 }}
                elevation={2}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardMedia
                        component="img"
                        sx={{
                            width: {
                                xs: '100%', // Full width on extra small screens
                                sm: '100%',  // 50% width on small screens and above
                                md: 240,  // 30% width on medium screens and above 
                            },
                            borderRadius: 4,
                        }}
                        image="dmykLogo.jpeg"
                        alt="Dört Mevsim Yaşam Köşkü"
                    />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <CardHeadingSubTitle subTitle="Dört Mevsim Yaşam Köşkü'ne Hoş Geldiniz!" variant='h6' bgColor={bgColor} />

                        <Typography variant="subtitle1" component="div">
                            Dört Mevsim Yaşam Köşkü, sıcak bir ortamda konforlu bir yaşam sunar. Yaşlılarımıza özenle hizmet veriyor ve onların huzurlu bir yaşam sürmelerini sağlıyoruz.
                        </Typography>
                        <Typography variant="subtitle1" component="div">
                            Bize emanet ettiğiniz akrabalarınızı en iyi şekilde ağırlamak için buradayız.
                        </Typography>
                    </CardContent>
                </Box>

            </Card>

        </Paper>
    );
}
