import React, { useRef, useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Card, CardContent, CardMedia, Typography, Box, IconButton, Stack, CardHeader, CardActions, Alert } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import './styles.css'; // Your custom styles
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Button, MobileStepper, Paper } from "@mui/material";
import { useTheme } from '@mui/material/styles'
import SliderProggressBar from '../customComponents/SliderProggressBar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { blueGrey, lightBlue } from '@mui/material/colors';
import CardHeading, { CardHeadingSubTitle } from '../customComponents/CardHeading';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

export default function HomeGalleryCarousel() {

  const cardsLength = cards.length;
  const theme = useTheme();
  const isDarkTheme = theme.palette.mode  === 'dark';
  const [index, setActiveStep] = React.useState(0);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [cardMediaWidth, setCardMediaWidth] = React.useState('65%'); 
  const [bgColor, setBgColor] = React.useState();

  useEffect(() => {
    if (isSmallScreen) {
      setCardMediaWidth('100%'); 
    } else {
      setCardMediaWidth('65%');  
    }
  }, [isSmallScreen]);

 

  useEffect(() => {
    if (isDarkTheme) { 
        setBgColor('rgba(0, 0, 0, 0.3)');
    } else { 
        setBgColor('rgb(245, 245, 245, 0.3)');
    }
}, [isDarkTheme]);


  const goToNextPicture = () => {
    let nextPage = (index + 1) % cardsLength;
    setActiveStep(nextPage);
  };

  const goToPrevPicture = () => {
    let prevPage = (index - 1 + cardsLength) % cardsLength;
    setActiveStep(prevPage);
  };
  const currentCard = cards[index];


  return (

    <Card key={index} elevation={4} sx={{
      px: 2, borderRadius: 4,
      backgroundColor: 'transparent',
      pt:2
    }} >
   
        <CardHeading title="Galeri" subTitle='Yaşam köşkümüzden kareler..'   bgColor={bgColor}
                iconComponent={<PhotoCameraIcon color="primary" />}
            />

      <Stack spacing={1} direction={isSmallScreen ? "column" : "row"}  marginTop={2}>

        <CardMedia sx={{ width: cardMediaWidth , borderRadius: 4}}
          component="img"
          alt={currentCard.title}
          //width="200"
          height="400"
          image={currentCard.image}
        />

        <CardContent sx={{    borderRadius: 4 ,
      backgroundColor: bgColor, }} >
          <CardHeadingSubTitle subTitle={currentCard.subTitle} variant='h6' bgColor={bgColor} />
{/* 
          <Typography variant="h5" mb={2}  > 
            {currentCard.subTitle}
          </Typography> */}
          <Typography variant="body2"  >
            {currentCard.description}
          </Typography>
        </CardContent>
      </Stack>
      <CardActions sx={{borderRadius: 4,m:1,  backgroundColor: bgColor}}>
        <SliderProggressBar handleAction={goToNextPicture} index={index} interval={1000} />
      </CardActions>
      <CardActions>

        <MobileStepper
          sx={{ width: '100%', backgroundColor: bgColor }}
          variant="dots"
          position="static"
          steps={cardsLength}
          activeStep={index}
          backButton={
            <IconButton
              onClick={goToPrevPicture}
            >
              <KeyboardArrowLeftIcon fontSize='medium' />

            </IconButton>
          }
          nextButton={
            <IconButton
              onClick={goToNextPicture}
            >
              <KeyboardArrowRightIcon fontSize='medium' />
            </IconButton>
          }
        />
      </CardActions>

    </Card>
  );
}

const cards = [
  {
    title: 'Bakım ve Güvenlik',
    subTitle: 'Emanetinize Güvenle Bakıyoruz',
    description: 'Yaşlı bireylerin sağlığı ve güvenliği bizim için en önemli önceliktir. Profesyonel bakım ekibimiz, 24/7 hizmet vererek sağlık sorunlarına anında müdahale eder ve günlük ihtiyaçlarınızı karşılar. Modern güvenlik sistemlerimiz ve deneyimli personelimizle, sakinlerimizin güvenliğini sağlamak için sürekli çaba gösteriyoruz.',
    image: 'dmyk1.png',
  },
  {
    title: 'Sağlık Hizmetleri',
    subTitle: 'Herşeyin Başı Sağlık',
    description: 'Sağlıklı bir yaşam için gerekli tıbbi bakımı sunuyoruz. Deneyimli hemşirelerimiz ve doktorlarımız, düzenli sağlık kontrolleri, ilaç takibi ve acil durum müdahaleleri ile sizin sağlığınızı koruma konusunda hassas davranırlar.',
    image: 'dmyk2.png',
  },
  {
    title: 'Sosyal Aktiviteler ve Eğlence',
    subTitle: 'Sosyalleşmek ve Eğlenmek İçin Her Fırsatı Değerlendiriyoruz',
    description: 'Huzur evimizde sadece bir konut değil, aynı zamanda bir topluluk bulacaksınız. Her gün düzenlenen sosyal etkinlikler, grup aktiviteleri ve kültürel etkinlikler ile sakinlerimiz birbirleriyle bağlantı kurabilir ve keyifli vakit geçirebilirler.',
    image: 'dmyk3.png',

  },
  {
    title: 'Beslenme ve Yemek Hizmetleri',
    subTitle: 'Leziz Ev Yemekleri ile Sıcak Bir Aile Ortamı Tesis Ediyoruz',
    description: 'Sağlıklı beslenme, yaşam kalitesini artırmanın önemli bir yolu. Uzman şeflerimiz tarafından hazırlanan lezzetli ve dengeli yemekler, sakinlerimizin beslenme ihtiyaçlarını karşılamak üzere özenle hazırlanmaktadır.',
    image: 'dmyk4.png',

  },
  {
    title: 'Kişisel Bakım ve Temizlik',
    subTitle: 'Temizlik ve Kişisel Bakım Konusunda Konforunuz Bizim İçin Önemli',
    description: 'Temizlik ve kişisel bakım konusunda konforunuz bizim için çok önemli. Profesyonel bakım ekibimiz, kişisel bakımınızı üstlenir ve yaşam alanlarınızın temizliğini sağlar.',
    image: 'dmyk5.png',

  },
  {
    title: 'Rahat Yaşam Alanları',
    subTitle: 'Modern ve Konforlu Yaşam Alanları',
    description: 'Modern ve konforlu yaşam alanlarımız, sakinlerimize evlerini aratmayacak bir ortam sunar. Bireysel odalarınızda konfor içinde dinlenebilir ve özel anlarınızı yaşayabilirsiniz.',
    image: 'dmyk5.png',

  },
];
