import React, { useEffect } from 'react';
import { Card, CardContent, CardMedia, Typography, IconButton, Stack, CardActions } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { MobileStepper } from "@mui/material";
import { useTheme } from '@mui/material/styles'
import SliderProggressBar from '../customComponents/SliderProggressBar';
import useMediaQuery from '@mui/material/useMediaQuery';
import CardHeading from '../customComponents/CardHeading';

import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

export default function GalleryCarousel() {

  const cardsLength = cards.length;
  const theme = useTheme();
  const isDarkTheme = theme.palette.mode === 'dark';
  const [index, setActiveStep] = React.useState(0);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [cardMediaWidth, setCardMediaWidth] = React.useState('65%');
  const [cardContentWidth, setCardContentWidth] = React.useState('35%');
  const [bgColor, setBgColor] = React.useState();


  useEffect(() => {
    if (isDarkTheme) {
      setBgColor('rgba(0, 0, 0, 0.3)');

    } else {
      setBgColor('rgb(245, 245, 245, 0.3)');
    }
  }, [isDarkTheme]);

  useEffect(() => {
    if (isSmallScreen) {
      setCardMediaWidth('100%');
      setCardContentWidth('100%');
    } else {
      setCardMediaWidth('65%');
      setCardContentWidth('65%');
    }
  }, [isSmallScreen]);



  const goToNextPicture = () => {
    let nextPage = (index + 1) % cardsLength;
    setActiveStep(nextPage);
  };

  const goToPrevPicture = () => {
    let prevPage = (index - 1 + cardsLength) % cardsLength;
    setActiveStep(prevPage);
  };
  const currentCard = cards[index];


  return (

    <Card key={index} elevation={4} sx={{
      px: 2, borderRadius: 4,
      backgroundColor: 'transparent',
    }}>
      <CardHeading title="Etkinliklerimiz" subTitle='Etkinliklerimizden kareler..' bgColor={bgColor}
        iconComponent={<VolunteerActivismIcon color="primary" />}
      />

      <Stack spacing={1} direction={isSmallScreen ? "column" : "row"} >

        <CardMedia sx={{ width: cardMediaWidth, borderRadius: 4 }}
          component="img"
          alt={currentCard.title}
          //width="200"
          min-height="400"
          image={currentCard.image}
        />

        <CardContent sx={{ width: cardContentWidth, backgroundColor: bgColor, borderRadius: 4 }} >
          <Typography variant="h5" mb={2}  >
            {currentCard.subTitle}
          </Typography>
          <Typography variant="body2"  >
            {currentCard.description}
          </Typography>
        </CardContent>
      </Stack>
      <CardActions sx={{ borderRadius: 4, m: 1, backgroundColor: bgColor }}>
        <SliderProggressBar handleAction={goToNextPicture} index={index} interval={1000} />
      </CardActions>
      <CardActions>

        <MobileStepper
          sx={{ width: '100%', backgroundColor: bgColor }}
          variant="dots"
          position="static"
          steps={cardsLength}
          activeStep={index}
          backButton={
            <IconButton
              onClick={goToPrevPicture}
            >
              <KeyboardArrowLeftIcon fontSize='medium' />

            </IconButton>
          }
          nextButton={
            <IconButton
              onClick={goToNextPicture}
            >
              <KeyboardArrowRightIcon fontSize='medium' />
            </IconButton>
          }
        />
      </CardActions>

    </Card>
  );
}

const cards = [
  {
    title: 'SANAT TERAPİSİ',
    subTitle: 'SANAT TERAPİSİ',
    description: `Yaşlılarda sanat terapisi, yaşlanma sürecinde karşılaşılan çeşitli zorluklarla başa çıkmaya yardımcı olmak, yaşam kalitesini artırmak ve yaşlıların duygusal, zihinsel ve sosyal ihtiyaçlarını desteklemek için kullanılan bir terapi yöntemidir. Bu terapi türü, yaşlıların yaşam deneyimlerini ifade etmelerine, duygusal olarak rahatlamalarına ve kişisel gelişimlerine katkıda bulunmalarına olanak tanır.
    Yaşlılar için sanat terapisi şu şekillerde fayda sağlayabilir:
    1. Duygusal İyileşme: Sanat terapisi, yaşlıların içlerinde biriktirdikleri duyguları ifade etmelerine ve işlemelerine yardımcı olabilir. Bu, depresyon, yalnızlık, kaygı gibi duygusal zorluklarla başa çıkmalarına yardımcı olabilir.
    2. Bellek ve Zihinsel Uyarım: Sanat terapisi, yaşlıların belleklerini uyarabilir ve bilişsel becerilerini güçlendirebilir. Resim yapma, müzik yapma veya hikaye yazma gibi faaliyetler, zihinsel aktiviteyi teşvik eder.
    3. Sosyal Bağlantılar: Sanat terapisi grup ortamlarında uygulandığında, yaşlılar arasında sosyal bağlantıları güçlendirebilir. Birlikte çalışma ve paylaşma deneyimleri, yaşlıların sosyal destek sistemlerini genişletmelerine yardımcı olabilir.
    4. Kendini İfade Etme: Sanat, yaşlılara kendilerini ifade etme ve özgüvenlerini artırma fırsatı sunar. Kendi yeteneklerini keşfetme ve yaratıcı potansiyellerini gerçekleştirme deneyimleri, yaşlıların kişisel gelişimlerine katkıda bulunabilir.
    5. Stres Azaltma ve Rahatlama: Sanat terapisi, yaşlılara stresi azaltma ve rahatlama sağlayabilir. Boyama, çizim veya el işi gibi faaliyetler, yaşlıların zihinlerini sakinleştirebilir ve ruh hallerini iyileştirebilir.
    Bu gibi faydaların yanı sıra, sanat terapisi yaşlılarda yaşam kalitesini artırabilir ve yaşlanma sürecini daha olumlu bir şekilde deneyimlemelerine yardımcı olabilir. Bu nedenle, yaşlı bakım merkezleri, toplum merkezleri ve evde bakım hizmetleri gibi kurumlar, yaşlılara sanat terapisi sunarak onların sağlıklı ve mutlu bir yaşam sürmelerine katkıda bulunabilir.
    `,
    image: 'sanat_terapisi.jpg',
  },
  {
    title: 'GRUP TERAPİSİ',
    subTitle: 'GRUP TERAPİSİ',
    description: 'Grup terapisi  destekleyici bir ortamda, benzer şikâyetleri olan yaşlılar arasında etkileşimi sağlayan bir tekniktir. Grup terapisi kişilerarası öğrenme ve ilişkiler açısından en doğal ve etkili terapi yöntemlerinden biridir. Bireysel terapiden tamamen farklı bir yöntem olmakla birlikte bireysel terapiye başvuran yaşlılar için grup terapinin farklı yararları vardır. Grup terapisi, psikoterapi tekniklerinin bir grup danışanda uygulanmasıdır. Grup içerisindeki yaşlıların sözel ya da davranışsal girişimlerle semptomlarını gidermeyi, uyuma yönelik olmayan davranışlarını değiştirmeyi ya da diğer bir ifadeyle kişilik değişimini hedefler.',
    image: 'grup_terapisi.jpg',
  },
  {
    title: 'KAHKAHA YOGASI',
    subTitle: 'KAHKAHA YOGASI',
    description: `Kahkaha yogası, yaşlılarda fiziksel ve zihinsel sağlığı desteklemek için kullanılan eğlenceli ve yenilikçi bir egzersiz yöntemidir. Bu egzersiz, gülmenin doğal olarak getirdiği faydalardan yararlanır ve genellikle gülme tekniklerini, nefes egzersizlerini ve basit bedensel hareketleri içerir.
    Yaşlılarda kahkaha yogasının faydaları şunlar olabilir:
    1. Fiziksel Sağlık: Kahkaha yogası, yaşlıların vücutlarını hareket ettirmelerini teşvik eder. Basit hareketlerle birlikte yapılan gülme egzersizleri, kasları güçlendirebilir, esnekliği artırabilir ve dengeyi iyileştirebilir.
    2. Zihinsel Sağlık: Gülme, beyinde endorfin ve serotonin gibi mutluluk hormonlarının salgılanmasını tetikler. Bu da yaşlılarda ruh halini iyileştirebilir, stresi azaltabilir ve genel zihinsel sağlığı destekleyebilir.
    3. Bağışıklık Sistemi Güçlendirme: Gülme, bağışıklık sisteminin güçlenmesine yardımcı olabilir. Araştırmalar, düzenli olarak gülmenin bağışıklık sistemi üzerinde olumlu bir etkisi olduğunu göstermektedir, bu da yaşlıların hastalıklara karşı direncini artırabilir.
    4. Sosyal Bağlantılar: Kahkaha yogası grup ortamlarında yapılıyorsa, yaşlılar arasındaki sosyal bağlantıları güçlendirebilir. Birlikte gülme deneyimi paylaşmak, insanlar arasında güçlü bağlar oluşturabilir.
    5. Stres Azaltma ve Rahatlama: Gülme, yaşlılarda stresi azaltabilir ve rahatlama sağlayabilir. Gülme sırasında gevşeyen kaslar ve artan oksijen akışı, vücutta bir rahatlama hissi yaratabilir.
    Kahkaha yogası, yaşlılar için hem fiziksel hem de zihinsel sağlık açısından faydalı bir egzersiz yöntemi olarak kabul edilir. Bu egzersiz, yaşlılar için keyifli ve eğlenceli bir aktivite olabilirken aynı zamanda sağlık ve refahlarını artırmaya da yardımcı olabilir.`,
    image: 'kahkaha_yogasi.jpg',

  },
  {
    title: 'TİYATRO',
    subTitle: 'TİYATRO',
    description: `Yaşlılarla tiyatro etkinlikleri, yaşlıların yaratıcılıklarını, iletişim becerilerini ve sosyal bağlarını güçlendirmek için harika bir yol olabilir. Bu tür etkinlikler, yaşlıların duygusal ifade yeteneklerini artırırken aynı zamanda eğlenceli bir deneyim sunar. İşte yaşlılarla tiyatro etkinliklerinin bazı faydaları ve nasıl planlanabileceği hakkında bazı ipuçları:
    Faydaları:
    1. İletişim ve Sosyal Becerileri Geliştirme: Tiyatro etkinlikleri, yaşlıların bir araya gelip birlikte çalışmasını ve birbirleriyle etkileşimde bulunmasını sağlar. Bu, iletişim becerilerini ve sosyal bağlarını güçlendirebilir.
    2. Özgüveni Artırma: Sahne üzerinde performans sergilemek, yaşlıların özgüvenlerini artırabilir. Başkalarının önünde konuşma ve rol yapma gibi aktiviteler, yaşlıların kendilerine olan güvenlerini artırabilir.
    3. Yaratıcılığı Teşvik Etme: Tiyatro, yaşlıların yaratıcı düşünme ve kendilerini ifade etme yeteneklerini geliştirebilir. Karakterlerin canlandırılması, hikaye oluşturma ve sahneleme süreçleri, yaşlıların yaratıcılıklarını teşvik eder.
    4. Stresi Azaltma ve Eğlence: Tiyatro etkinlikleri, yaşlıların stresini azaltabilir ve eğlenceli bir ortamda zaman geçirmelerini sağlar. Sahne arkasında çalışmak ve performans sergilemek, yaşlılara keyifli bir deneyim sunabilir.`,
    image: 'kahkaha_yogasi.jpg',

  },
  {
    title: 'YARIŞMALAR',
    subTitle: 'YARIŞMALAR',
    description: `Huzurevinde düzenlenen yarışmalar, yaşlıların katılımını teşvik etmek, onları sosyal etkileşime dahil etmek ve keyifli bir zaman geçirmelerini sağlamak için harika bir fikir olabilir. Bu tür etkinlikler, yaşlıların ruh sağlığını desteklerken aynı zamanda onların yeteneklerini sergileme fırsatı da sunar. İşte huzurevinde düzenlenebilecek çeşitli yarışma fikirleri:

    1. Şarkı Yarışması: Yaşlıların sevdikleri şarkıları söyleyebilecekleri bir şarkı yarışması düzenleyin. Gruplar veya bireyler halinde performanslar sergileyebilirler. En iyi ses, en iyi performans veya en eğlenceli şarkı gibi kategoriler belirleyebilirsiniz.
    2. Karaoke Yarışması: Karaoke yarışması düzenleyerek yaşlıların şarkı söyleme yeteneklerini sergilemelerine olanak tanıyın. Katılımcılar, sevdikleri şarkıları seslendirerek jüri veya diğer yaşlılar arasında oylamaya sunulabilir.
    3. Bilgi Yarışması: Genel kültür, tarih, müzik veya sinema gibi çeşitli konularda bir bilgi yarışması düzenleyin. Katılımcılar, belirli bir süre içinde soruları cevaplayarak puan kazanmaya çalışabilirler.
    4. El Sanatları Yarışması: Yaşlılara çeşitli el işi veya sanat projeleri üzerinde çalışma fırsatı verin. Sonunda, yapılan el işleri veya sanat eserleri jüri veya diğer yaşlılar tarafından değerlendirilerek ödüller verilebilir.
    5. Yemek Yarışması: Yaşlıların sevdikleri tarifleri kullanarak bir yemek yarışması düzenleyin. Katılımcılar, kendi yemeklerini hazırlayıp jüriye sunarak en iyi yemek ödülü için yarışabilirler.
    6. Dans Yarışması: Dans yarışması düzenleyerek yaşlıların dans yeteneklerini sergilemelerine olanak tanıyın. Çeşitli dans tarzlarında veya gruplar halinde performanslar sergileyebilirler.
    7. Moda Şovu: Moda şovu düzenleyerek yaşlıların kıyafet tarzlarını ve giyim zevklerini sergilemelerine olanak tanıyın. Katılımcılar, kendi tarzlarını ve kombinasyonlarını jüri veya diğer yaşlılar önünde sergileyebilirler.
    
    Bu yarışmaların her biri, yaşlıların farklı ilgi alanlarını ve yeteneklerini yansıtırken aynı zamanda onların sosyal etkileşimlerini artırmalarına yardımcı olabilir. Ödüllerin verilmesi ve katılımcıların teşvik edilmesi, etkinliğin daha keyifli ve anlamlı olmasını sağlayabilir.
    `,
    image: 'yarismalar.jpg',

  },
  {
    title: 'BOYAMA ETKİNLİKLERİ',
    subTitle: 'BOYAMA ETKİNLİKLERİ',
    description: `1. Yaşlılarla boyama etkinlikleri, yaşlıların zihinsel ve duygusal sağlığını desteklemenin yanı sıra keyifli bir etkileşim ve sosyal deneyim sağlayan bir etkinlik türüdür. Bu tür etkinlikler, yaşlıların bir araya gelerek yaratıcı bir şekilde vakit geçirmelerini sağlar ve genellikle sakinleştirici ve terapötik bir etki yaratır. İşte yaşlılarla boyama etkinliklerinin bazı faydaları:
    2. Zihinsel Uyarım: Boyama etkinlikleri, yaşlıların zihinsel uyarımını artırır. Renk seçimi, desen oluşturma ve detaylara dikkat etme gibi aktiviteler, bilişsel becerilerin kullanımını teşvik eder.
    3. Duygusal Rahatlama: Boyama, yaşlıların duygusal rahatlama ve stres azaltma ihtiyacını karşılar. Boyama yaparken odaklanma, rahatlama ve iç huzuru artırabilir.
    4. Yaratıcılığı Teşvik: Yaşlılar için boyama, yaratıcı ifadeyi teşvik eder. Farklı renkleri kullanma, desenler oluşturma ve kendi tarzlarını ortaya koyma fırsatı sunar.
    5. Sosyal Bağlar: Boyama etkinlikleri, yaşlılar arasında pozitif etkileşim ve sosyal bağların gelişmesini sağlar. Birlikte boyama yapma deneyimi, grup içi etkileşimi artırabilir ve arkadaşlık kurmaya yardımcı olabilir.
    6. Estetik Zevki Artırma: Boyama yapmak, yaşlıların güzellik ve estetik algısını artırabilir. Renklerin ve desenlerin uyumuyla ilgilenme, estetik zevki geliştirebilir ve yaşamın keyifli yönlerini vurgular.
    
    Yaşlılarla boyama etkinlikleri düzenlerken, malzemelerin erişilebilir olmasına ve etkinliğin herkesin katılımına uygun olduğuna dikkat etmek önemlidir. Ayrıca, katılımcıların farklı beceri seviyelerine ve tercihlerine saygı göstermek ve onları cesaretlendirmek de önemlidir. Boyama etkinlikleri, yaşlılar için keyifli bir deneyim sunarken aynı zamanda ruhsal ve zihinsel sağlıklarını destekler.
    `,
    image: 'boyama.png',

  },
  {
    title: 'BEYİN FIRTINALARI',
    subTitle:'BEYİN FIRTINALARI',
    description: `Beyin fırtınası, bir problemin çözümü ya da bir konunun geliştirilmesi için kullanılan, serbest çağrışımın etkili olduğu bir yöntemdir. Yeni bakış açıları geliştirmeye, konuyu daha iyi kavramaya ve bağlantılı diğer konuları keşfetmeye yardımcı olur. Bu şekilde yeni fikirler ve yaratıcı çözümler üretmeyi sağlar.    `,
    image: 'beyin_firtinasi.png',

  },
  {
    title: 'AÇIK HAVA SPOR AKTİVİTELERİ',
    subTitle:'AÇIK HAVA SPOR AKTİVİTELERİ',
    description: `Yaşlılarla açık hava etkinlikleri, onların fiziksel sağlığını desteklemenin yanı sıra doğayı deneyimleme, sosyal etkileşimleri artırma ve genel refahlarını iyileştirme açısından önemlidir. Açık havada yapılan etkinlikler, yaşlıların yaşam kalitesini artırabilir ve ruh sağlığını olumlu yönde etkileyebilir. İşte yaşlılarla yapılacak açık hava etkinlikleri için bazı öneriler:

    1. Doğa Yürüyüşleri: Yakınlarda bulunan parklar, bahçeler veya doğal alanlarda düzenlenecek doğa yürüyüşleri, yaşlılar için mükemmel bir açık hava etkinliği olabilir. Yavaş tempolu yürüyüşler, hem fiziksel aktivite sağlar hem de doğanın tadını çıkarmalarını sağlar.
    2. Piknikler: Güzel bir hava olduğunda, yaşlılarla birlikte piknik yapmak keyifli olabilir. Güneşin tadını çıkarmak, taze hava almak ve leziz yiyeceklerle keyifli bir öğle geçirmek, yaşlıların ruh sağlığını olumlu yönde etkileyebilir.
    3. Bahçe Çalışmaları: Eğer bir bahçe veya açık hava alanınız varsa, yaşlılarla birlikte bahçe çalışmaları yapabilirsiniz. Bitki dikme, çiçek sulama, sebze hasadı gibi aktiviteler, hem fiziksel olarak aktif olmalarını sağlar hem de doğayla etkileşimlerini artırır.
    4. Açık Hava Yoga ve Meditasyon: Hafif yoga ve meditasyon seansları, açık havada yapılabilir. Yoga ve meditasyonun sağladığı rahatlama ve zindelik duygusu, yaşlıların stresi azaltmalarına ve iç huzuru bulmalarına yardımcı olabilir.
    5. Resim veya El İşi Etkinlikleri: Açık havada resim yapma veya el işi etkinlikleri düzenlemek de harika bir fikirdir. Yaşlılar, doğadan esinlenerek resim yapabilir veya açık havada el işi projeleri üzerinde çalışabilirler.
    
    Bu tür etkinlikler, yaşlıların fiziksel sağlığını desteklerken aynı zamanda sosyal bağlarını güçlendirir ve genel refahlarını artırır. Etkinlikleri planlarken yaşlıların ihtiyaçlarını ve tercihlerini dikkate almak önemlidir, böylece herkesin katılımını ve keyif almasını sağlayabilirsiniz.`,
    image: 'acik_hava.png',

  },
  {
    title: 'ÖZEL GÜN KUTLAMALARI',
    subTitle:  'ÖZEL GÜN KUTLAMALARI',
    description: `Yaşlılarla özel gün kutlamaları, sevdikleriyle birlikte keyifli zaman geçirmelerini sağlayarak onların mutluluğunu ve bağlarını güçlendirebilecek harika bir fırsattır. Özel günler, yaşlılar için anlamlı ve keyifli bir deneyim sunar ve bu tür kutlamalar, yaşlıların hayatlarında özel anılar oluşturabilir. İşte yaşlılarla yapılacak özel gün kutlamaları için bazı fikirler:

    Doğum Günü Kutlamaları: Yaşlıların doğum günlerini kutlamak, onları özel hissettirebilir. Basit bir pasta, sevdikleriyle birlikte yapılan bir parti veya sürpriz hediyeleşme gibi etkinlikler düzenleyebilirsiniz.
    Anneler Günü ve Babalar Günü: Anneler Günü ve Babalar Günü gibi özel günlerde, yaşlıların anneleri ve babaları için anlamlı etkinlikler düzenlemek güzel olabilir. Örneğin, özel bir brunch, aile fotoğraf albümü gösterimi veya anıları paylaşma gibi etkinlikler düzenleyebilirsiniz.
    Dini ve Kültürel Bayramlar: Yaşlılar için önemli olan dini ve kültürel bayramları kutlamak, onların ruhsal ve duygusal sağlığına katkıda bulunabilir. Örneğin, bayram yemeği, geleneksel dans gösterisi veya müzik dinletisi gibi etkinlikler düzenleyebilirsiniz.
    Yaşlılar Günü: Dünya Yaşlılar Günü gibi özel günlerde, yaşlılar için özel etkinlikler düzenlemek güzel olabilir. Örneğin, yaşlılara özel bir kahvaltı, hediye dağıtımı, müzik dinletisi veya toplu bir etkinlik gibi aktiviteler planlayabilirsiniz.
    Bu tür özel gün kutlamaları, yaşlıların hayatlarında anlamlı ve keyifli anılar oluşturabilir. Etkinlikleri planlarken yaşlıların ilgi ve ihtiyaçlarını dikkate almak önemlidir, böylece onların keyif alacağı bir deneyim sunabilirsiniz.
    `,
    image: 'special_days.png',

  },
];
