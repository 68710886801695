import React from 'react';

const DieteticsContent = () => {
    // Your component logic goes here

    return (
        <div> 
            <span >Huzurevlerinde yaşlılara sunulan beslenme ve diyet hizmetleri, yaşlıların sağlıklı bir şekilde beslenmelerini sağlamak, özel diyet ihtiyaçlarını karşılamak ve genel sağlık durumlarını iyileştirmeyi amaçlar. Bu hizmetler, yaşlıların sağlıklı ve dengeli bir şekilde beslenmelerini sağlamak için çeşitli unsurları içerir. İşte huzurevlerinde yaşlılara sunulan beslenme ve diyet hizmetlerinin bazı örnekleri
            </span>
            <ol>
                <li>
                    <b>Sağlıklı Menüler : </b>
                    Huzurevlerinde yaşlılara sağlıklı ve dengeli beslenmeyi teşvik etmek amacıyla özel olarak hazırlanmış menüler sunulur. Bu menüler, yaşlıların besin ihtiyaçlarını karşılayacak şekilde çeşitlendirilmiş ve dengelenmiş besinler içerir. Taze sebzeler, meyveler, protein kaynakları, tam tahıllar ve sağlıklı yağlar gibi besin gruplarına önem verilir.

                </li>
                <li>
                    <b> Özel Diyetler : </b>
                    Huzurevlerinde yaşlılara özel diyet ihtiyaçlarına uygun beslenme seçenekleri sunulabilir. Diyabet, yüksek tansiyon, kalp rahatsızlıkları gibi sağlık sorunlarına özel olarak düzenlenmiş düşük şekerli, düşük sodyumlu veya düşük yağlı diyetler gibi özel diyetler sağlanabilir.
                </li>
                <li>
                    <b>Diyet Danışmanlığı : </b>
                    Huzurevlerinde yaşlılara beslenme ve diyet konusunda uzmanlık sunan diyetisyenler veya beslenme uzmanları bulunabilir. Bu uzmanlar, yaşlıların beslenme ihtiyaçlarını değerlendirir, özel diyet planları oluşturur ve sağlıklı yeme alışkanlıkları hakkında eğitim verirler.
                </li>
                <li>
                    <b>Yemek Servisi ve Yeme Yardımı : </b>
                    Huzurevlerinde yaşlılara yemek servisi ve yeme yardımı hizmetleri sunulur. Yaşlılar, öğün saatlerinde yemeklerini taze ve sıcak bir şekilde servis edilmiş bir şekilde alabilirler. Ayrıca, yemek yeme konusunda yardıma ihtiyaç duyan yaşlılara destek sağlanır.
                </li>
                <li>
                    <b> Beslenme Değerlendirmesi ve İzleme : </b>
                    Huzurevlerinde yaşlıların beslenme durumları düzenli olarak değerlendirilir ve izlenir. Bu değerlendirmeler, yaşlıların besin alımını ve kilo durumunu izlemeyi içerir ve gerekirse beslenme planları güncellenir.
                </li>
            </ol>
            <span>
                Bu hizmetler, yaşlıların sağlıklı ve dengeli bir şekilde beslenmelerini sağlamak ve genel sağlık durumlarını iyileştirmek için tasarlanmıştır. Huzurevi personeli, yaşlıların bireysel beslenme ihtiyaçlarını dikkate alır ve kişiselleştirilmiş beslenme planları oluşturarak en uygun hizmeti sunmaya çalışır. Ayrıca, yaşlıların beslenme sürecindeki ilerlemeleri düzenli olarak izler ve beslenme planlarını gerektiğinde güncellerler.
            </span>
        </div >
    );
};

export default DieteticsContent;