import React, { useRef, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Alert, CardHeader, Paper, Stack } from '@mui/material';
import MapContainer from './MapContainer';
import CardHeading from '../customComponents/CardHeading';
import NavigationIcon from '@mui/icons-material/Navigation';

export default function MapCard() {

    const theme = useTheme();
    const isDarkTheme = theme.palette.mode === 'dark';
    const [bgColor, setBgColor] = React.useState();


    useEffect(() => {
        if (isDarkTheme) {
            setBgColor('rgba(0, 0, 0, 0.3)');

        } else {
            setBgColor('rgb(245, 245, 245, 0.3)');
        }
    }, [isDarkTheme]);


    return (
        <Paper sx={{
            borderRadius: 6,
            backgroundColor: 'transparent',
            p: 2
        }} elevation={2}>
            <CardHeading title="Yol Tarifimiz" subTitle="Harita'daki konumumuz"  bgColor={bgColor}

iconComponent={<NavigationIcon color="primary" />}
            />
            <Card sx={{ width: '100%', borderRadius: 4, backgroundColor: 'transparent' }}>
                

                <CardContent sx={{ flex: '1 0 auto', background: 'transparent' }}>

                    <Typography variant="subtitle1" component="div">
                        Aşağıdaki harita üzerinden yol tarifinizi alabilirsiniz. Harita kullanımında bir problem yaşarsanız lütfen bize dilediğiniz kanaldan ulaşın.
                    </Typography>
                </CardContent>
                <CardContent sx={{ flex: '1 0 auto', borderRadius: 8 }}>
                    <MapContainer />
                </CardContent>

            </Card>
        </Paper>
    );
}
