import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Paper } from '@mui/material';
import TodayIcon from '@mui/icons-material/Today';


export function CardHeadingSubTitle({ iconComponent, subTitle, bgColor , variant="caption"}) {
    return (
        <Paper elevation={0} sx={{ backgroundColor: bgColor, p: 1, m: 1, borderRadius: 4, width: '96%'  }} >
            <Box display="flex" alignItems="center" alignContent='center' >
                {iconComponent && <Box marginRight={2}>{iconComponent}</Box>}
                {subTitle &&
                    <Typography variant={variant} component="caption" gutterBottom sx={{
                        color: 'primary',
                    }}>
                        {subTitle.toLocaleUpperCase('tr-TR')}
                    </Typography>
                }
            </Box>
        </Paper>
    );
}


export function CardTimeHeading({ bgColor }) {
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);
    const getSubTitle = () => {
        return currentDateTime.toLocaleDateString("tr-TR") + " " + currentDateTime.toLocaleTimeString("tr-TR");
    }

    return (
        <Paper elevation={0} sx={{ backgroundColor: bgColor, p: 2, m: 1, borderRadius: 4 }} >
            <Box display="flex" alignItems="center" alignContent='center'>
                <Box marginRight={2}> <TodayIcon color='primary'/></Box>
                <Typography variant="caption" component="caption" gutterBottom sx={{
                    color: 'primary',
                }}>
                    {getSubTitle()}
                </Typography>
            </Box>
        </Paper >
    );
}

export default function CardHeading({ title, subTitle, bgColor, iconComponent }) {

    return (
        <Paper elevation={0} sx={{ backgroundColor: 'transparent', borderRadius: 4 }} >
            <Box display="flex" flexDirection='column' sx={{ backgroundColor: bgColor,  p: 1, borderRadius: 4 }}>
                <Paper elevation={1} sx={{ backgroundColor: 'transparent', p: 2, m: 1, borderRadius: 4 }} >

                    {title &&
                        <Typography variant="h6" component="h5" gutterBottom sx={{
                            color: 'primary.main',
                        }}>
                            {title.toLocaleUpperCase('tr-TR')}
                        </Typography>
                    }
                    {subTitle ?
                        <CardHeadingSubTitle iconComponent={iconComponent} subTitle={subTitle} bgColor={bgColor} />
                        :
                        <CardTimeHeading bgColor={bgColor} />
                    }
                </Paper>
            </Box>
        </Paper>

    );
}
