import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Outlet } from "react-router-dom";
import { Container, Paper } from "@mui/material";
import AppNavBar from "./AppNavBar";

const Layout = ({ mode, toggleColorMode }) => {


  const theme = useTheme();
  const isDarkTheme = theme.palette.mode === 'dark';
  const [paperBackgorund, setPaperBackgorund] = React.useState(`url('dark-bg-inv.jpg')`);
  const containerBackground = 'rgb(0, 90, 90, 0.1)';
  const layoutBackground = {
    background: paperBackgorund,
    position: 'relative',
    m: 0, p: 3,
    mt: 10,
    minHeight: '98vh',
    
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover', // Adjust this property based on your needs
  };


  useEffect(() => {
    if (isDarkTheme) {
      setPaperBackgorund(`url('dark-hexa.jpg')`);

    } else {
      setPaperBackgorund(`url('light-hexa.jpg')`);
    }
  }, [isDarkTheme]);

  return (
    <Paper   >
      <Paper sx={layoutBackground}      >
      <AppNavBar mode={mode} toggleColorMode={toggleColorMode} />
        <Container sx={{ backgroundColor: containerBackground, py: 2, px: 1, borderRadius: 8 }}>
          <Outlet />
        </Container>
      </Paper>
    </Paper>
  );
}
export default Layout;
