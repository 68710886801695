import React, { useRef, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Alert, CardHeader, Link, Paper, Typography, Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import { blue } from '@mui/material/colors';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import HomeIcon from '@mui/icons-material/Home';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import CardMedia from '@mui/material/CardMedia';
import CardHeading from '../customComponents/CardHeading';
import Groups2Icon from '@mui/icons-material/Groups2';

export default function SocialMediaCard() {

    const theme = useTheme();
    const isDarkTheme = theme.palette.mode === 'dark';
    const [bgColor, setBgColor] = React.useState();


    useEffect(() => {
        if (isDarkTheme) {
            setBgColor('rgba(0, 0, 0, 0.3)');

        } else {
            setBgColor('rgb(245, 245, 245, 0.3)');
        }
    }, [isDarkTheme]);


    const facebookLink = 'www.facebook.com/share/NspeK326sigaymT6/?mibextid=WC7FNe';
    const xLink = 'www.x.com/4mevsimhuzurevi';
    const instagramLink = 'www.instagram.com/4mevsimyasamkoskuhuzurevi';
    const goToFacebookAtNewTab = () => {
        alert('Facebook sayfamıza yönlendiriliyorsunuz.');
        window.open(facebookLink, '_blank');
    }
    const goToXAtNewTab = () => {
        alert('X sayfamıza yönlendiriliyorsunuz.');
        window.open(xLink, '_blank');
    }
    const goToInstagramAtNewTab = () => {
        alert('X sayfamıza yönlendiriliyorsunuz.');
        window.open(instagramLink, '_blank');
    }
    return (
        <Paper sx={{
            borderRadius: 6,
            backgroundColor: 'transparent',
            p: 2
        }} elevation={2}>
            <CardHeading title="Bize Sosyal Medya'dan ulaşın!" subTitle="Sosyal Medya Hesaplarımız"  bgColor={bgColor}

                iconComponent={<Groups2Icon color="primary" />}
            />
            <CardContent sx={{ flex: '1 0 auto', background: 'transparent' }}>

                <Typography variant="subtitle1" component="div">
                    Bizi sosyal medyada takip ederek güncel duyurularımıza, etkinliklerimize ve huzur evi yaşamına dair paylaşımlarımıza ulaşabilirsiniz.
                </Typography>
            </CardContent>
            <Card sx={{ borderRadius: 6, display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', sm: 'column', md: 'row' }, m: 2, background: 'inherit' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', background: 'inherit' }}>
                    <CardMedia
                        component="img"
                        sx={{
                            width: 240,
                        }}
                        image="social-acc.png"
                        alt="Adresimiz"
                    />
                </Box>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
                    <CardContent sx={{ flex: '1 0 auto', background: 'transparent' }}>
                        <List sx={{ width: '100%' }}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <FacebookIcon fontSize='large' color='success' />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="caption"
                                        color="text.secondary"
                                    >
                                        Facebook:
                                    </Typography>}
                                    secondary={
                                        <Stack direction="column">

                                            <Link onClick={goToFacebookAtNewTab} underline="none" color="inherit" sx={{ color: blue[500] }}

                                                css={{ backgroundColor: 'transparent', onmouseover: { backgroundColor: 'transparent' } }}>
                                                www.facebook.com/dortmevsimnursinghome
                                            </Link>
                                        </Stack>
                                    }
                                />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <InstagramIcon fontSize='large' color='success' />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="caption"
                                        color="text.secondary"
                                    >
                                        Instagram:
                                    </Typography>}
                                    secondary={
                                        <Stack direction="column">

                                            <Link onClick={goToInstagramAtNewTab} underline="none" color="inherit" sx={{ color: blue[500] }}

                                                css={{ backgroundColor: 'transparent', onmouseover: { backgroundColor: 'transparent' } }}>
                                                www.instagram.com/dortmevsimnursinghome
                                            </Link>
                                        </Stack>
                                    }
                                />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <XIcon fontSize='large' color='success' />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="caption"
                                        color="text.secondary"
                                    >
                                        X (Twitter):
                                    </Typography>}
                                    secondary={
                                        <Stack direction="column">

                                            <Link onClick={goToXAtNewTab} underline="none" color="inherit" sx={{ color: blue[500] }}

                                                css={{ backgroundColor: 'transparent', onmouseover: { backgroundColor: 'transparent' } }}>
                                                www.x.com/dortmevsimnursinghome
                                            </Link>
                                        </Stack>
                                    }
                                />
                            </ListItem>
                        </List>
                    </CardContent>

                </Box>

            </Card>

        </Paper>
    );
}
