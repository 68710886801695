import React, { useRef, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Alert, CardHeader, Paper } from '@mui/material';
import PageHeading from '../customComponents/PageHeading';
import CardHeading, { CardHeadingSubTitle } from '../customComponents/CardHeading';

export default function EnrtyCard() {
    const theme = useTheme();
    const isDarkTheme = theme.palette.mode === 'dark';
    const [bgColor, setBgColor] = React.useState();

    useEffect(() => {
        if (isDarkTheme) {
            setBgColor('rgba(0, 0, 0, 0.3)');

        } else {
            setBgColor('rgb(245, 245, 245, 0.3)');
        }
    }, [isDarkTheme]);



    return (
        <Paper sx={{
            borderRadius: 4,
            backgroundColor: 'transparent',
            p: 2,

        }} elevation={2}>
            <CardHeading title="Dört Mevsim Yaşam Köşkü" bgColor={bgColor}
                iconComponent={<img src="logo.png" alt="Dört Mevsim Yaşam Köşkü" style={{ width: 40, height: 40 }} />}
            />

            <Card sx={{ borderRadius: 4, display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', sm: 'column', md: 'row', backgroundColor: bgColor }, p: 4, my: 2 }}
                elevation={2}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardMedia
                        component="img"
                        sx={{
                            width: {
                                xs: '100%', // Full width on extra small screens
                                sm: '100%',  // 50% width on small screens and above
                                md: 240,  // 30% width on medium screens and above 
                            },
                            borderRadius: 4,
                        }}
                        image="dmykLogo.jpeg"
                        alt="Dört Mevsim Yaşam Köşkü"
                    />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <CardHeadingSubTitle subTitle="Dört Mevsim Yaşam Köşkü'ne Hoş Geldiniz!" variant='h6' bgColor={bgColor} />

                        <Typography variant="subtitle1" component="div" sx={{ my: 2 }}>
                            Dört Mevsim Yaşam Köşkü Huzurevi ve Yaşlı Bakım Merkezi ailesi olarak kurumumuzda Anayasal  kanun ve yönetmeliklere uygun bina, uzman ve gerekli eğitimleri tamamlamış tecrübeli personeli ve Aile Çalışma ve Sosyal Hizmet il müdürlüğünden alınan izinle nitelikli yaşam sürdürmede güçlük çeken veya öz bakım ihtiyaçlarını gideremeyen yaşlılarımızın  yaşam standartlarının yükseltebilmek, toplumumuzun deneyim hazinesi yaşlılarımızı gereken saygı ve şefkatle bakılmasını sağlamak amacıyla açılan kurumumuz kaliteli hizmeti uygun fiyatla sunmayı ilke edinmiştir.
                        </Typography>
                        <Typography variant="subtitle1" component="div" sx={{ my: 2 }}>
                            Dört Mevsim Yaşam Köşkü Huzurevi ve Yaşlı Bakım Merkezi alanında uzman yönetim ve personeliyle uyum içerisinde güler yüzlü, işini zevkle yapan kişilerden seçilmiş, temelinde sevgi ve saygıya dayalı bir kadro ile  yaşlılarımıza ev konfor ve sıcaklığında profesyonel hizmet vermeyi  hedefleyen bir özel bakım kurumudur. Kurumumuz sakinlerimizin öz bakım, fizyoterapi ve tıbbi takiplerinin yanı sıra Sanat Terapisi, Grup Terapisi, Kahkaha Yogası, Tiyatro, Sıra Geceleri, Yılbaşı Eğlenceleri gibi birçok sosyal faaliyetle sakinlerimizin kaliteli ve eğlenceli vakit geçirmesini sağlayarak Aile Ve Sosyal Hizmetler İl müdürlüğünün, sakin ve yakınlarının takdir ve beğenisini toplamıştır. Dört Mevsim Yaşam Köşkü Huzurevi ve Yaşlı Bakım Merkezi olarak evimiz kabul ettiğimiz kurumumuzda sizi de evinize davet ediyoruz.
                        </Typography>
                        <Typography variant="subtitle1" component="div" sx={{ my: 2 }}>
                            Dört Mevsim Yaşam Köşkü Huzurevi ve Yaşlı Bakım Merkezi <b> ''Huzurevi Ötesi Bir Yaşam Alternatifi''</b>
                        </Typography>
                    </CardContent>
                </Box>

            </Card>

        </Paper>
    );
}
