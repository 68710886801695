import React, { useRef, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Paper, Stack } from '@mui/material';
import CardHeading, { CardHeadingSubTitle } from '../customComponents/CardHeading'; 
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';

export default function HomeAccordion() {
    const theme = useTheme();
    const isDarkTheme = theme.palette.mode === 'dark';
    const [bgColor, setBgColor] = React.useState();

    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    useEffect(() => {
        if (isDarkTheme) {
            setBgColor('rgba(0, 0, 0, 0.3)');
        } else {
            setBgColor('rgb(245, 245, 245, 0.3)');
        }
    }, [isDarkTheme]);


    return (

        <Paper sx={{
            borderRadius: 4, m: 2, p: 2,
            backgroundColor: 'transparent'
        }}>
            <CardHeading title="Biz Kimiz" subTitle='Kısaca biz ?' bgColor={bgColor}
                iconComponent={<PsychologyAltIcon color="primary" />}
            />
            <Stack direction="column" spacing={2} sx={{ backgroundColor: bgColor }} marginTop={2}>

                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} elevation={3} sx={{ borderRadius: 3, backgroundColor: bgColor }}  >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <CardHeadingSubTitle subTitle="Biz Kimiz?" variant='h6' bgColor={bgColor} />

                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Dört Mevsim Yaşam Köşkü, 2015 yılında kurulmuş olup, 7/24 hizmet veren bir yaşlı bakım merkezidir. Yaşlı bireyler için özel olarak tasarlanmış bir yaşam alanıdır. Modern tesisimiz, konforlu odalar, geniş ortak alanlar ve çeşitli aktivitelerle donatılmıştır.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} elevation={3} sx={{ borderRadius: 3, backgroundColor: bgColor }}  >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                    >
                        <CardHeadingSubTitle subTitle="Hizmetlerimize Neler Dahil?" variant='h6' bgColor={bgColor} />
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Dört Mevsim Yaşam Köşkü olarak sunduğumuz hizmetler arasında sağlık hizmetleri, güvenlik, beslenme programları, sosyal etkinlikler ve kişiselleştirilmiş bakım bulunmaktadır.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} elevation={3} sx={{ borderRadius: 3, backgroundColor: bgColor }}  >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3bh-content"
                    >
                        <CardHeadingSubTitle subTitle="Personel ve Ekibimiz" variant='h6' bgColor={bgColor} />

                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Deneyimli ve uzman personelimiz, sizlere en iyi hizmeti sunmak için burada. Sıcakkanlı ekibimiz, her bir sakinimizin bireysel ihtiyaçlarına özen gösterir.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} elevation={3} sx={{ borderRadius: 3, backgroundColor: bgColor }}  >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                    >
                        <CardHeadingSubTitle subTitle="Sosyal Sorumluluk" variant='h6' bgColor={bgColor} />

                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Dört Mevsim Yaşam Köşkü; tüm personelleri ile topluma olan sorumluluğumuzun bilincindedir. Sosyal etkinlikler ve projelere katılımımızla yaşlılarımıza destek oluyoruz.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} elevation={3} sx={{ borderRadius: 3, backgroundColor: bgColor }}  >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                    >
                        <CardHeadingSubTitle subTitle="Referanslar ve Memnuniyet" variant='h6' bgColor={bgColor} />
 
                    </AccordionSummary>
                    <AccordionDetails >
                        <Typography>
                            Huzur Evi'nde yaşayanlar ve aileleriyle yapılan röportajları inceleyin. Memnuniyetlerini ve deneyimlerini keşfedin.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Stack>
        </Paper>
    );
}
