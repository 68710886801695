import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from "react-router-dom";
import { Stack } from '@mui/material';
import { blue, green } from '@mui/material/colors';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';

const pagesWtihRoutes = [
    { page: 'Hakkımızda', route: '/hakkimizda' },
    { page: 'Hizmetlerimiz', route: '/hizmetlerimiz' },
    { page: 'Etkinliklerimiz', route: '/etkinliklerimiz' },
    { page: 'Bize Ulaşın', route: '/iletisim' },];

function AppNavBar({ mode, toggleColorMode }) {
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [selectedItem, setSelectedItem] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const handleMenuItemClick = (page) => {
        setSelectedItem(page);
        navigate(page.route);
    };
    const goHomePage = () => {
        setSelectedItem(null);
        setAnchorElNav(null);
        navigate('/');
    };

    return (

        <AppBar position="fixed" sx={{ backgroundColor: '#184849' }}>
            <Container maxWidth="xl" >
                <Toolbar disableGutters>
                    <img src="dmykLogo.jpeg" alt="fireSpot" style={{ width: "4rem", margin: "0.5rem", cursor: "pointer" }}
                        onClick={goHomePage} />
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        onClick={goHomePage}
                        sx={{
                            mr: 2,
                            display: { xs: "none", md: 'none', lg: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 400,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            cursor: "pointer"
                        }}
                    >

                        {"DÖRT MEVSİM YAŞAM KÖŞKÜ"}
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: 'flex', lg: 'none', } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { md: 'block', lg: 'none' }
                            }}
                        >

                            {pagesWtihRoutes.map((page) => (
                                <MenuItem key={page.page}
                                    onClick={() => {
                                        handleMenuItemClick(page);
                                        setSelectedItem(page);
                                        handleCloseNavMenu();
                                    }}
                                    sx={{
                                        my: 2,
                                        display: 'block',
                                        mx: 2,
                                        // color: page === selectedItem ? green[500] : 'white',
                                        color: green[900],
                                        backgroundColor: page === selectedItem ? green[100] : 'transparent',
                                        '&:hover': {
                                            backgroundColor: 'white',
                                            //  color: green[800],
                                        },
                                    }}
                                >
                                    <Button >{page.page}</Button>
                                </MenuItem>
                            ))}
                            <MenuItem
                                sx={{
                                    my: 1,
                                    display: 'block',
                                    mx: 1,
                                    color: green[900],
                                    backgroundColor: 'transparent',
                                    '&:hover': {
                                        backgroundColor: 'white',
                                        //color: green[500],
                                    },
                                }}
                                onClick={toggleColorMode}
                            >
                                <IconButton sx={{ ml: 1 }} >
                                    {mode === 'dark' ? <LightModeIcon fontSize='medium'  sx={{
                                    my: 1,
                                    display: 'block',
                                    mx: 1,
                                    color: green[900],
                                    backgroundColor: 'transparent',
                                    '&:hover': {
                                        backgroundColor: 'white',
                                        //color: green[500],
                                    },
                                }}/> : <  DarkModeIcon fontSize='medium'  sx={{
                                    my: 1,
                                    display: 'block',
                                    mx: 1,
                                    color: green[900],
                                    backgroundColor: 'transparent',
                                    '&:hover': {
                                        backgroundColor: 'white',
                                        //color: green[500],
                                    },
                                }}/>}
                                </IconButton>
                            </MenuItem>
                        </Menu>
                    </Box>

                    <Typography
                        onClick={goHomePage}
                        variant="h5"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            display: { xs: "none", md: 'flex', lg: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 400,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        {"DÖRT MEVSİM YAŞAM KÖŞKÜ"}
                    </Typography>
                    <Typography
                        onClick={goHomePage}
                        variant="h5"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            display: { xs: "flex", md: 'none', lg: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 400,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        {"DÖRT MEVSİM"}
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: "none", md: 'none', lg: 'flex' } }}>
                        {pagesWtihRoutes.map((page) => (
                            <Button
                                key={page.page}
                                onClick={() => {
                                    handleMenuItemClick(page);
                                    setSelectedItem(page);
                                }}
                                sx={{
                                    my: 2,
                                    display: 'block',
                                    mx: 2,
                                    // color: page === selectedItem ? green[500] : 'white',
                                    color: 'white',
                                    backgroundColor: page === selectedItem ? green[600] : 'transparent',
                                    '&:hover': {
                                        backgroundColor: green[900],
                                        //  color: green[800],
                                    },
                                }}
                            >
                                {page.page}
                            </Button>
                        ))}

                        <Box
                            sx={{
                                my: 2,
                                display: 'block',
                                color: "inherit",
                                mx: 2,
                                '&:hover': {
                                    backgroundColor: 'transparent ',
                                },
                            }}>
                            <IconButton sx={{ ml: 1 }} onClick={toggleColorMode} color="inherit">
                                {mode === 'dark' ? <LightModeIcon fontSize='medium' /> : <  DarkModeIcon fontSize='medium' />}
                            </IconButton>
                        </Box>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>

    );
}
export default AppNavBar;
