import React, {  } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography'; 
import { CardHeadingSubTitle } from './CardHeading';
 
const CustomAccordion = ({title, content, bgColor}) => { 

  return (   
          <Accordion expanded={true}  elevation={3} sx={{ borderRadius: 3 ,backgroundColor: bgColor }}  >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
                <CardHeadingSubTitle subTitle=  {title} variant='h6' bgColor={bgColor} />
 
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
               {content}
              </Typography>
            </AccordionDetails>
          </Accordion>
  );
};

export default CustomAccordion;
