import React from 'react';

const HealthContent = () => {
    // Your component logic goes here

    return (
        <div>
            <span >Huzurevlerinde yaşlılara sunulan sağlık hizmetleri, yaşlıların sağlık durumlarını takip etmek, sağlık sorunlarına müdahale etmek ve genel refahlarını artırmak için çeşitli hizmetler içerir. Bu hizmetler, yaşlıların yaşam kalitesini artırmayı ve sağlık sorunlarının yönetimini kolaylaştırmayı amaçlar. İşte yaşlılara huzurevinde sunulan sağlık hizmetlerinin bazıları:
                <ol>
                    <li>
                        <b>Tıbbi Muayeneler ve Danışmanlık : </b> Huzurevlerinde yaşlılara düzenli tıbbi muayeneler ve danışmanlık hizmetleri sunulur. Doktorlar veya sağlık uzmanları, yaşlıların sağlık durumlarını değerlendirir, hastalık belirtilerini izler ve gerektiğinde tedavi planları oluşturur.

                    </li>
                    <li>
                        <b>İlaç Yönetimi : </b>
                        Yaşlıların düzenli olarak alması gereken ilaçları takip etmek ve yönetmek için huzurevi personeli tarafından ilaç yönetimi hizmetleri sunulur. Bu, ilaçların doğru dozda ve zamanında alınmasını sağlamak için önemlidir.
                    </li>
                    <li>
                        <b>Acil Tıbbi Müdahale : </b>
                        Huzurevlerinde acil tıbbi durumlar için hazırlıklı olunur. Yaşlılara hızlı bir şekilde tıbbi müdahale sağlamak için acil durum prosedürleri ve ekipmanları bulunur.
                    </li>
                    <li>
                        <b>Fizik Tedavi ve Rehabilitasyon : </b>
                        Yaşlıların fiziksel sağlığını iyileştirmek ve hareket kabiliyetlerini artırmak için fizik tedavi ve rehabilitasyon hizmetleri sunulabilir. Bu hizmetler, yaşlıların güçlenmesine, dengelerini korumalarına ve bağımsızlıklarını sürdürmelerine yardımcı olur.
                    </li>
                    <li>
                        <b>Hasta Bakımı ve Yara Bakımı : </b>
                        Huzurevlerinde yaşlılara yara bakımı ve hasta bakımı hizmetleri sunulabilir. Yara bakımı, bası yaraları gibi yaygın sorunların yönetimini içerirken, hasta bakımı yaşlıların genel sağlık ve hijyen ihtiyaçlarını karşılamayı amaçlar.
                    </li>
                    <li>
                        <b> Sağlık Eğitimi ve Farkındalık : </b>
                        Yaşlılara sağlıkla ilgili eğitimler ve farkındalık programları düzenlenir. Bu programlar yaşlıları sağlık sorunları konusunda bilinçlendirir ve kendi sağlıklarını korumak için gerekli bilgi ve becerileri kazandırır.
                        Bu sağlık hizmetleri, yaşlıların sağlık ve refahlarını korumak ve iyileştirmek için tasarlanmıştır. Huzurevi personeli, yaşlıların bireysel sağlık ihtiyaçlarını dikkate alır ve kişiselleştirilmiş sağlık planları oluşturarak en iyi hizmeti sunmaya çalışır. Ayrıca, yaşlıların sağlık durumlarını izlemek ve gelişmeleri takip etmek için düzenli olarak sağlık durumlarını değerlendirirler.
                    </li>
                </ol>
            </span>
        </div>
    );
};

export default HealthContent;