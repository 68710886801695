import React, { useRef, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Container, Typography, Box, TextField, Button, Stack, Paper, AlertTitle } from '@mui/material';
import MapContainer from './MapContainer';
import ContactEnrtyCard from './ContactEnrtyCard';
import AddressCard from './AddressCard';
import SocialMediaCard from './SocialMediaCard';
import MapCard from './MapCard';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import PageHeading from '../customComponents/PageHeading';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


const Contact = () => {

  const theme = useTheme();
  const isDarkTheme = theme.palette.mode === 'dark';
  const [bgColor, setBgColor] = React.useState();

  useEffect(() => {
    if (isDarkTheme) {
      setBgColor('rgba(0, 0, 0, 0.3)');

    } else {
      setBgColor('rgb(245, 245, 245, 0.3)');
    }
  }, [isDarkTheme]);


  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));


  return (<Paper sx={{
    borderRadius: 8,
    p: 2,
    backgroundColor: 'transparent',
  }}
    elevation={0}>

    <PageHeading title="Bize Ulaşın" />
    <Stack direction="column" spacing={4} justifyContent="center" sx={{ borderRadius: 4, }} >
      <ContactEnrtyCard />
      <Stack spacing={2} direction="column" sx={{ borderRadius: 4, }}      >
        <AddressCard />
        <SocialMediaCard />
        <MapCard />
      </Stack>
    </Stack>
  </Paper>
  );
};

export default Contact;
