import React from 'react';

const PsychosocialSupportContent = () => {
    // Your component logic goes here

    return (
        <div>

            <span >Huzurevlerinde yaşlılara sunulan sosyal hizmetler, yaşlıların sosyal etkileşimlerini artırmak, ruhsal ve duygusal ihtiyaçlarını karşılamak, yaşam kalitesini yükseltmek ve toplumla bağlantılarını sürdürmelerine yardımcı olmayı amaçlar. Bu hizmetler, yaşlıların sosyal, duygusal ve zihinsel refahlarını destekleyerek onların mutlu ve tatmin edici bir yaşam sürmelerine katkıda bulunur. İşte huzurevlerinde yaşlılara sunulan sosyal hizmetlerin bazı örnekleri
            </span>
            <ol>
                <li>
                    <b>Sosyal Etkinlikler ve Programlar: </b>
                    Huzurevlerinde çeşitli sosyal etkinlikler ve programlar düzenlenir. Bu etkinlikler arasında müzik dinletileri, dans dersleri, el sanatları atölyeleri, resim kursları, kitap kulüpleri, oyun geceleri, film gösterimleri ve bahçe etkinlikleri gibi aktiviteler yer alır. Bu etkinlikler yaşlıların sosyal etkileşimde bulunmalarını teşvik eder ve sosyal bağlarını güçlendirir.

                </li>
                <li>
                    <b>Psikososyal Destek ve Danışmanlık : </b>
                    Huzurevlerinde yaşlılara psikososyal destek ve danışmanlık hizmetleri sunulur. Yaşlılar, duygusal zorluklarla başa çıkmak, ilişki sorunlarını ele almak, kaygı ve depresyonla mücadele etmek gibi konularda profesyonel danışmanlık alabilirler. Bu hizmetler, yaşlıların ruhsal ve duygusal refahlarını desteklemeyi amaçlar.
                </li>
                <li>
                    <b>Toplumla Bağlantılar : </b>
                    Huzurevleri, yaşlıların toplumla bağlantılarını sürdürmelerine olanak tanır. Bu bağlamda, toplumla etkileşimi teşvik eden etkinlikler düzenlenebilir ve yerel topluluklarla işbirlikleri kurulabilir. Bu, yaşlıların dış dünya ile bağlantılarını korumalarına ve toplumda aktif bir rol oynamalarına olanak sağlar.

                </li>
                <li>
                    <b>Eğitim ve Bilgilendirme : </b>
                    Huzurevlerinde yaşlılara çeşitli konularda eğitim ve bilgilendirme programları düzenlenebilir. Bu programlar, sağlık konuları, güvenlik bilgisi, finansal yönetim, teknoloji kullanımı gibi konularda yaşlıların bilgi düzeyini artırmayı hedefler. Bu sayede yaşlılar, kendilerini geliştirirken aynı zamanda topluma katkıda bulunabilirler.
                </li>
                <li>
                    <b>  Aile ve Ziyaretçi Programları : </b>
                    Huzurevlerinde aile ziyaretleri ve sosyal ziyaretçi programları düzenlenebilir. Bu programlar, yaşlıların sevdikleriyle zaman geçirmelerini sağlar ve sosyal izolasyonun önlenmesine yardımcı olur. Ayrıca, yaşlıların sosyal ağlarını genişletmelerine ve yeni arkadaşlıklar kurmalarına da olanak tanır.
                </li>
            </ol>
            <span>
                Bu sosyal hizmetler, yaşlıların sosyal, duygusal ve ruhsal ihtiyaçlarını karşılamak ve yaşam kalitelerini artırmak için önemlidir. Huzurevi personeli, yaşlıların bireysel tercihlerini ve ihtiyaçlarını dikkate alarak çeşitli sosyal hizmetler sunar ve yaşlıların sosyal bağlarını güçlendirmeye çalışır.
            </span>
        </div >
    );
};

export default PsychosocialSupportContent;