import React, { useRef, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { Box, Stack, Paper, Container } from '@mui/material'; 
import CustomAccordion from '../customComponents/CustomAccordion';
import PageHeading from '../customComponents/PageHeading';
import CardHeading from '../customComponents/CardHeading';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import EnrtyCard from './EntryCard';

const accordions = [
  {
    title: 'Biz Kimiz?',
    content: 'Dört Mevsim Yaşam Köşkü, 2015 yılında kurulmuş olup, 7/24 hizmet veren bir yaşlı bakım merkezidir. Yaşlı bireyler için özel olarak tasarlanmış bir yaşam alanıdır. Modern tesisimiz, konforlu odalar, geniş ortak alanlar ve çeşitli aktivitelerle donatılmıştır.'
  },
  {
    title: 'Deneyim ve Güven',
    content: 'Dört Mevsim Yaşam Köşkü, köklü bir geçmişe sahip olup, yaşlı bireylere yönelik bakım ve hizmetlerde deneyim kazanmış bir ekibe sahiptir. Uzman personelimiz, her bir sakinimize bireysel özen göstererek, yaşamlarını daha anlamlı hale getirmelerine yardımcı olur.'
  },
  {
    title: 'Modern ve Güvenli Tesis',
    content: 'Her bireyin özgün ihtiyaçlarına saygı gösteriyoruz. Yaşlılarımızın bireysel tercihleri, sağlık durumları ve kişisel hikayeleri üzerinde titizlikle durarak, onlara özel bir bakım sunarız.'
  },
  {
    title: 'Sosyal Etkinlikler ve Topluluk',
    content: 'Dört Mevsim Yaşam Köşkü sakinleri için düzenlediğimiz sosyal etkinliklerle bir araya gelir, anılar oluştururuz. Topluluk ruhunu güçlendirmek, yaşam kalitesini artırmak için çeşitli aktiviteler düzenleriz.'
  },
  {
    title: 'İletişim ve Açıklık',
    content: 'Dört Mevsim Yaşam Köşkü olarak, sakinlerimizin aileleri ile güçlü bir iletişim kurmayı önemseriz. Açık kapı politikamız ve düzenlediğimiz etkinliklerle aileleri, sevdikleriyle bir araya getiririz.'
  },
];
const AboutUs = () => {
  const theme = useTheme();
  const isDarkTheme = theme.palette.mode === 'dark';
  const [bgColor, setBgColor] = React.useState();


  useEffect(() => {
    if (isDarkTheme) {
      setBgColor('rgba(0, 0, 0, 0.3)');

    } else {
      setBgColor('rgb(245, 245, 245, 0.3)');
    }
  }, [isDarkTheme]);


  return (
    <Paper sx={{
      borderRadius: 8,
      p: 2,
      backgroundColor: 'transparent',
    }}
      elevation={0}>
      <PageHeading title="Hakkımızda" />
      <EnrtyCard />

      <Stack direction="column" spacing={2} sx={{ mt: 4 }}  >
        <CardHeading title="Biz Kimiz" subTitle='Kısaca biz ?' bgColor={bgColor}
          iconComponent={<PsychologyAltIcon color="primary" />}
        />
        {accordions.map((accordion, index) => (
          <CustomAccordion
            bgColor={bgColor}
            key={index}
            title={accordion.title}
            content={accordion.content}
          />
        ))}
      </Stack>
    </Paper>
  );
};

export default AboutUs;
