
import { ThemeProvider, createTheme } from '@mui/material';
import { ThemeOptions } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { useEffect } from 'react';

export const customTheme  = (mode) => {
    
  const lightTheme = {
    palette: {
      mode: "light",
      background: {
        default: 'red',
        //paper: 'rgba(f, f, f, 0.1)',
      },
      primary: {
        main: '#218e69',
      },
      secondary: {
        main: '#f50057',
      },
      text: {
        secondary: 'rgba(43,41,41,0.6)',
      },
    },    
  };


  const darkTheme = {
    palette: {
      mode: "dark",
      background: {
        default: '#6d6c6c',
        //paper: 'rgba(f, f, f, 0.1)',
      },
      primary: {
        main: '#12cac4',
      },
      secondary: {
        main: '#f50057',
      },
      text: {
        secondary: '#9ac7a8',
      },
    },    
  };
  
  const themeOptionsArray = [lightTheme,darkTheme ];

  const theme = createTheme(themeOptionsArray.find((theme) => theme.palette.mode === mode));

   
  return theme;
}