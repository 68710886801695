import React, { useState, useEffect } from 'react';
import { useLoadScript, GoogleMap, MarkerF, InfoWindowF } from '@react-google-maps/api';
import { Link } from 'react-router-dom';

const markers = [
  {
    id: 1,
    name: 'Dört Mevsim Yaşam Köşkü Yaşlı Bakım Merkezi',
    address: <div>
      <p>Mutlukent </p>
      <p> Binsesin sitesi ümitköy, 1964. Sk. No 39</p>
      <p>06800 Çankaya/Ankara/Türkiye</p>
    </div>,
    link : "https://www.google.com/maps/dir//Mutlukent,+D%C3%B6rt+Mevsim+Ya%C5%9Fam+K%C3%B6%C5%9Fk%C3%BC+Ya%C5%9Fl%C4%B1+Bak%C4%B1m+Merkezi,+Binsesin+sitesi+%C3%BCmitk%C3%B6y,+1964.+Sk.+No+39,+06800+%C3%87ankaya%2FAnkara,+T%C3%BCrkiye/@39.898282,32.708825,20z/data=!4m8!4m7!1m0!1m5!1m1!1s0x14d3387c9ad66393:0x613d89fbb1a4bcea!2m2!1d32.7089242!2d39.8982872?hl=tr-TR&entry=ttu",
    goToLinkText: `Yol Tarifi Al`,

    position: { lat: 39.89829964319582, lng: 32.708923906747685 }
  }
]

const MapContainer = () => {
  const [activeMarker, setActiveMarker] = useState(null);

  useEffect(() => {
    setActiveMarker(markers[0]);
  }, []);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyA15BYEpQ8AZBFTF1kIF8eYxUP__o5CL_I",
    language: 'tr-TR'
  });

  return (
    <>
      {isLoaded ? (<GoogleMap
        mapContainerStyle={{ height: '600px', width: '100%' }}
        zoom={12}
        center={activeMarker?.position}
      //onClick={() => setActiveMarker(null)}
      >
        {markers.map(({ id, name, address, position, goToLinkText, link }) => (
          <MarkerF
            key={id}
            position={position}
            onClick={() => handleActiveMarker(id)}
            // icon={{
            //   url:"dmykLogo.jpeg",
            //   scaledSize: { width: 36, height: 36 },
            // }}
            label={name}
          >
            {activeMarker === id ? (
              <InfoWindowF onCloseClick={() => setActiveMarker(null)} >
                <div  >
                  <h4>
                    {name}
                  </h4>
                  <p>{address}</p>
                  <div>
                    <Link to={link} target='_blank' >{goToLinkText}</Link>
                  </div>
                </div>
              </InfoWindowF>
            ) : null}
          </MarkerF>
        ))}
      </GoogleMap>)
        : (<div>Harita Yükleniyor</div>)
      }
    </>

  );
};

export default MapContainer;
