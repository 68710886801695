import React, { useRef, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Alert, CardHeader, Paper } from '@mui/material';
import AddressInfo from './AddressInfo';
import CardHeading from '../customComponents/CardHeading';
import HomeIcon from '@mui/icons-material/Home';

export default function AddressCard() {

    const theme = useTheme();
    const isDarkTheme = theme.palette.mode === 'dark';
    const [bgColor, setBgColor] = React.useState();

    useEffect(() => {
        if (isDarkTheme) {
            setBgColor('rgba(0, 0, 0, 0.3)');

        } else {
            setBgColor('rgb(245, 245, 245, 0.3)');
        }
    }, [isDarkTheme]);

    return (
        <Paper sx={{
            borderRadius: 6,
            backgroundColor: 'transparent',
            p: 2
        }} elevation={2}>
            <CardHeading title="Adres ve İletişim Bilgilerimiz" subTitle="Çay İçmeye Bekliyoruz"  bgColor={bgColor}

                iconComponent={<HomeIcon color="primary" />}
            />
            <CardContent sx={{ flex: '1 0 auto', background: 'transparent' }}>
                <Typography variant="subtitle1" component="div">
                    Adres bilgilerimizi sizin için aşağıya bırakıyoruz. Arayabilir, mail atabilirsiniz. Çay içmeye bekleriz.
                </Typography>
            </CardContent>
            <Card sx={{ borderRadius: 6, display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', sm: 'column', md: 'row' }, m: 2, background: 'inherit' }}>

                <Box sx={{ display: 'flex', flexDirection: 'column', background: 'inherit' }}>
                    <CardMedia
                        component="img"
                        sx={{
                            width: {
                                xs: '100%', // Full width on extra small screens
                                sm: '100%',  // 50% width on small screens and above
                                md: 240,  // 30% width on medium screens and above 
                            },
                        }}
                        image="address-card.png"
                        alt="Adresimiz"
                    />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', background: 'transparent' }}>
                    <CardContent sx={{ flex: '1 0 auto', background: 'transparent' }}>
                        <AddressInfo />
                    </CardContent>
                </Box>

            </Card>

        </Paper>
    );
}
